import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import feather from 'feather-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WebSocketContext } from '../WebSocketContext';
import { handle403Error } from '../utils/handle403Error';

const INITIAL_USER_DETAILS = {
    id: "",
    userName: "",
    nickName: "",
    totalBalance: "",
    userStatus: "",
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const avatarUrl = "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"; // Avatar placeholder

const Navbar = React.memo(() => {
    const [userDetails, setUserDetails] = useState(INITIAL_USER_DETAILS);
    const navigate = useNavigate();
    const role = localStorage.getItem("role");
    const { balanceMap, connectWebSocket } = useContext(WebSocketContext);

    const getAuthHeaders = () => {
        return {};
    };

    const getAuthParams = () => {
        const apiKey = localStorage.getItem("apiKey");
        return {
            "api-key": apiKey,
        };
    };

    const fetchUserDetails = useCallback(async () => {
        const headers = getAuthHeaders();
        const params = getAuthParams();
        if (!params) return;

        try {
            const response = await api.get("/v1/admin/home/user-details", {
                headers,
                params,
            });

            const { data, success, error } = response.data;

            if (success) {
                setUserDetails(data);
            } else {
                toast.error(error);
            }
        } catch (error) {
            handle403Error(error, navigate);
        }
    }, [navigate]);

    useEffect(() => {
        if (!localStorage.getItem("apiKey")) {
            navigate("/login");
            return;
        }

        if (!userDetails.id) {
            fetchUserDetails();
        }
    }, [navigate, fetchUserDetails, userDetails.id]);

    useEffect(() => {
        feather.replace();
    }, [userDetails]);

    useEffect(() => {
        if (userDetails?.id && balanceMap[userDetails.id] !== undefined) {
            setUserDetails((prevState) => ({
                ...prevState,
                totalBalance: balanceMap[userDetails.id]
            }));
        }
    }, [balanceMap, userDetails.id]);

    useEffect(() => {
        connectWebSocket();
    }, [connectWebSocket]);

    const logout = () => {
        setUserDetails(INITIAL_USER_DETAILS);
        localStorage.clear();
        sessionStorage.removeItem("webSocketClient");
        document.title = "Diamond Casino";
        navigate("/login");
    };

    const handleSidebarToggle = () => {
        const sidebar = document.querySelector('.sidebar');
        const main = document.querySelector('.main');
        if (sidebar && main) {
            sidebar.classList.toggle('collapsed');
            main.classList.toggle('collapsed');
        }
    };

    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
            <div className="d-flex align-items-center">
                <div
                    className="sidebar-toggle js-sidebar-toggle"
                    onClick={handleSidebarToggle}
                >
                    <i className="hamburger align-self-center"></i>
                </div>
                {role !== 'ADMIN' && (
                    <span className="navbar-text ms-3" style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                        Balance: $ {userDetails.totalBalance || '0.00'}
                    </span>
                )}
            </div>
            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    <li className="nav-item dropdown">
                        <div className="nav-icon dropdown-toggle" id="alertsDropdown" style={{ cursor: 'pointer' }}>
                            <div className="position-relative">
                                <i className="align-middle" data-feather="bell"></i>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <div className="nav-icon dropdown-toggle d-inline-block d-sm-none" data-bs-toggle="dropdown">
                            <i className="align-middle" data-feather="settings"></i>
                        </div>

                        <div
                            className="nav-link dropdown-toggle d-none d-sm-inline-block"
                            data-bs-toggle="dropdown"
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={userDetails.avatarUrl || avatarUrl}
                                className="avatar img-fluid rounded me-1"
                                alt="User Avatar"
                            />
                            <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>{userDetails.userName || ''}</span>
                        </div>

                        <div className="dropdown-menu dropdown-menu-end">
                            <button 
                                className="dropdown-item" 
                                onClick={() => navigate("/profile", { state: { userDetails } })}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="align-middle me-1" data-feather="user"></i> Profile
                            </button>
                            <div className="dropdown-divider"></div>
                            <button className="dropdown-item"><i className="align-middle me-1" data-feather="help-circle"></i> Help Center</button>
                            <div className="dropdown-divider"></div>
                            <button className="dropdown-item" onClick={logout}><i className="align-middle me-1" data-feather="log-out"></i> Log out</button>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
});

export default Navbar;
