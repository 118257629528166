import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPencilAlt, faTrash, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from 'uuid'; // Import UUID

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const getApiKey = () => {
    const apiKey = localStorage.getItem('apiKey');
    if (!apiKey) {
        console.error('API key is missing');
        return null;
    }
    return apiKey;
};

function BroadcastMessage() {
    const [messages, setMessages] = useState([]);
    const [editedMessage, setEditedMessage] = useState({});
    const [editable, setEditable] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const inputRefs = useRef({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState(null);

    const fetchMessages = async () => {
        const apiKey = getApiKey();
        if (!apiKey) return;

        try {
            setLoading(true);
            const response = await api.get(`/v1/admin/home/getAdminBroadcastMessage`, {
                params: { 'api-key': apiKey },
            });

            if (response.status === 204 || !response.data.data || response.data.data.length === 0) {
                setMessages([]);
            } else if (response.data.success) {
                const fetchedMessages = (response.data.data || []).map((msg) => ({
                    ...msg,
                    id: uuidv4(), // Assign a unique UUID for internal handling
                    isNew: false, // Existing messages from the API are not new
                }));

                const initialEditable = {};
                fetchedMessages.forEach((msg) => {
                    initialEditable[msg.id] = false;
                });
                setMessages(fetchedMessages);
                setEditable(initialEditable);
            } else {
                toast.error('Failed to fetch messages');
            }
        } catch (error) {
            handle403Error(error);
            console.error('Error fetching messages:', error);
            setMessages([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    const handleInputChange = (id, value) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.id === id ? { ...msg, message: value } : msg
            )
        );
        setEditedMessage((prev) => ({ ...prev, [id]: value }));

        if (value.trim()) {
            setErrors((prev) => ({ ...prev, [id]: '' }));
        }
    };

    const handleSave = async (id, event) => {
        event.preventDefault();

        const messageToSave = editedMessage[id] ?? messages.find((msg) => msg.id === id)?.message;

        if (!messageToSave || messageToSave.trim() === '') {
            setErrors((prev) => ({ ...prev, [id]: 'Fill in the Message' }));
            setEditable((prev) => ({ ...prev, [id]: true }));
            return;
        }

        if (messageToSave.trim().length < 10 || messageToSave.trim().length > 75) {
            setErrors((prev) => ({
                ...prev,
                [id]: 'Message must be between 10 and 75 characters',
            }));
            setEditable((prev) => ({ ...prev, [id]: true }));
            return;
        }

        const apiKey = getApiKey();
        if (!apiKey) return;

        try {
            // Prepare the payload: only include existing messages and the one being edited
            const payload = messages
                .filter((msg) => !msg.isNew || msg.id === id) // Exclude new messages unless it's the one being edited
                .map((msg) => ({
                    message: msg.id === id ? messageToSave : msg.message,
                }));

            const response = await api.post(`/v1/admin/home/createBroadcastMessage`, payload, {
                params: { 'api-key': apiKey },
            });

            if (response.data.success) {
                toast.success(response.data.message || 'Message saved successfully');

                // Update the specific message in the state without reloading all messages
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.id === id ? { ...msg, message: messageToSave, isNew: false } : msg
                    )
                );
                setEditable((prev) => ({ ...prev, [id]: false }));
            } else {
                toast.error(response.data.error || 'An error occurred while saving');
            }
        } catch (error) {
            handle403Error(error);
            const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
            toast.error(errorMessage);
            console.error('Error saving message:', error);
        }
    };

    const confirmDelete = async () => {
        if (!selectedMessageId) return;

        const apiKey = getApiKey();
        if (!apiKey) return;

        try {
            const updatedMessages = messages.filter((msg) => msg.id !== selectedMessageId);

            const response = await api.post(`/v1/admin/home/createBroadcastMessage`, updatedMessages.map(msg => ({ message: msg.message })), {
                params: { 'api-key': apiKey },
            });

            if (response.data.success) {
                toast.success(response.data.message || 'Message deleted successfully');

                // Update the state to reflect the deletion without refetching all messages
                setMessages((prevMessages) => 
                    prevMessages.filter((msg) => msg.id !== selectedMessageId)
                );
            } else {
                toast.error(response.data.error || 'An error occurred while deleting');
            }
        } catch (error) {
            handle403Error(error);
            const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
            toast.error(errorMessage);
            console.error('Error deleting message:', error);
        } finally {
            setDeleteDialogOpen(false);
        }
    };

    const handleDeleteButtonClick = (id, event) => {
        event.preventDefault();
        setSelectedMessageId(id);
        setDeleteDialogOpen(true);
    };

    const toggleEdit = (id, event) => {
        event.preventDefault();
        setEditable((prev) => ({ ...prev, [id]: true }));

        setTimeout(() => {
            if (inputRefs.current[id]) {
                inputRefs.current[id].focus();
                inputRefs.current[id].setSelectionRange(
                    inputRefs.current[id].value.length,
                    inputRefs.current[id].value.length
                );
            }
        }, 0);
    };

    const handleClickOutside = useCallback(
        (event) => {
            Object.keys(inputRefs.current).forEach((id) => {
                if (
                    inputRefs.current[id] &&
                    !inputRefs.current[id].contains(event.target) &&
                    editable[id] &&
                    messages.find((msg) => msg.id === id)?.message
                ) {
                    setEditable((prev) => ({ ...prev, [id]: false }));
                }
            });
        },
        [editable, messages]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleAddMessage = () => {
        const newMessage = {
            id: uuidv4(), // Generate a unique ID for each new message
            message: '',
            isNew: true,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setEditable((prev) => ({ ...prev, [newMessage.id]: true }));
        setEditedMessage((prev) => ({ ...prev, [newMessage.id]: '' }));
        setErrors({});
    };

    const handleCloseCard = (id) => {
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[id];
            return updatedErrors;
        });
    };

    const buttonStyles = {
        base: {
            padding: '6px 10px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'background-color 0.3s, transform 0.2s',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        hover: {
            transform: 'scale(1.05)',
        },
        click: {
            transform: 'scale(0.95)',
        },
        addButton: {
            width: '160px',
            margin: '10px auto 0',
            display: 'block',
        },
        disabledEditButton: {
            backgroundColor: 'rgba(0, 123, 255, 0.6)', // Slightly darker blue with reduced opacity
            color: '#ffffff',
            cursor: 'not-allowed',
            opacity: 0.8,
            pointerEvents: 'none',
        },
        disabledDeleteButton: {
            backgroundColor: 'rgba(217, 83, 79, 0.6)', // Slightly darker red with reduced opacity
            color: '#ffffff',
            cursor: 'not-allowed',
            opacity: 0.8,
            pointerEvents: 'none',
        },
    };

    const handleMouseEnter = (e) => {
        e.target.style.transform = buttonStyles.hover.transform;
    };

    const handleMouseLeave = (e) => {
        e.target.style.transform = 'scale(1)';
    };

    const handleMouseDown = (e) => {
        e.target.style.transform = buttonStyles.click.transform;
    };

    const handleMouseUp = (e) => {
        e.target.style.transform = buttonStyles.hover.transform;
    };

    return (
        <div style={styles.container}>
            <ToastContainer />
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Delete Message</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this message?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h1 className="h3"><strong>Broadcast Message</strong></h1>
                </div>
            </div>
            {loading ? (
                <div className="MuiDataGrid-overlay">
                    <div className="MuiCircularProgress-root MuiCircularProgress-indeterminate">
                        <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44">
                            <circle
                                className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
                                cx="44"
                                cy="44"
                                r="20.2"
                                fill="none"
                                strokeWidth="3.6"
                            />
                        </svg>
                    </div>
                    Loading messages...
                </div>
            ) : (
                <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
                    {messages.map((msg, index) => (
                        <div key={msg.id} style={styles.card}>
                            {msg.isNew && (
                                <button
                                    style={styles.closeButton}
                                    onClick={() => handleCloseCard(msg.id)}
                                    aria-label="Close"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                            <label style={styles.label}>Message {index + 1}</label> {/* Display the message number based on index */}
                            <textarea
                                ref={(el) => (inputRefs.current[msg.id] = el)}
                                placeholder={`Message ${index + 1}`}
                                style={styles.textarea}
                                value={editedMessage[msg.id] ?? msg.message ?? ''}
                                onChange={(e) => handleInputChange(msg.id, e.target.value)}
                                readOnly={!editable[msg.id]}
                            />
                            {errors[msg.id] && (
                                <span style={styles.errorText}>{errors[msg.id]}</span>
                            )}
                            <div style={styles.buttonContainer}>
                                <button
                                    style={{
                                        ...buttonStyles.base,
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onClick={(e) => handleSave(msg.id, e)}
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} /> Save
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.base,
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                        ...(msg.isNew || editable[msg.id] ? buttonStyles.disabledEditButton : {}),
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onClick={(e) => toggleEdit(msg.id, e)}
                                    disabled={msg.isNew || editable[msg.id]} 
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} style={styles.icon} /> Edit
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.base,
                                        backgroundColor: '#d9534f',
                                        color: '#fff',
                                        ...(msg.isNew || editable[msg.id] ? buttonStyles.disabledDeleteButton : {}),
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onClick={(e) => handleDeleteButtonClick(msg.id, e)}
                                    disabled={msg.isNew || editable[msg.id]}
                                >
                                    <FontAwesomeIcon icon={faTrash} style={styles.icon} /> Delete
                                </button>
                            </div>
                        </div>
                    ))}

                    {!loading && messages.length === 0 && (
                        <p style={{ textAlign: 'center', color: 'red', marginBottom: '5px', marginTop: '-10px' }}>
                            No Message, Click the button Below to Add a New Message
                        </p>
                    )}

                    {!loading && messages.length < 7 && (
                        <button
                            style={{
                                ...buttonStyles.base,
                                backgroundColor: '#28a745',
                                color: '#fff',
                                ...buttonStyles.addButton,
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onClick={handleAddMessage}
                        >
                            <FontAwesomeIcon icon={faPlus} style={styles.icon} /> Add a Message
                        </button>
                    )}
                </form>
            )}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        margin: '0 auto',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    card: {
        position: 'relative',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        color: '#333',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: '8px',
        color: '#333',
    },
    textarea: {
        width: '100%',
        minHeight: '80px',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        resize: 'vertical',
        fontSize: '14px',
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
        marginTop: '4px',
    },
    buttonContainer: {
        display: 'flex',
        gap: '8px',
        marginTop: '10px',
        justifyContent: 'center',
    },
    icon: {
        marginRight: '6px',
    },
};

export default BroadcastMessage;
