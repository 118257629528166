import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { Button, styled, IconButton } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTopButton from '../components/ScrollToTopButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const Store = ({ userId, playerName, totalBalance, onClose }) => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [showNoMoreData, setShowNoMoreData] = useState(false); // Track if we should show "No more data"
    const [searchFields, setSearchFields] = useState({
        userName: '',
        totalBalance: '',
        userStatus: '',
        createdDate: '',
    });
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const navigate = useNavigate();
    const perPage = 20; // Number of records to fetch per page

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 70 },
        { field: 'userName', headerName: 'Store Name', flex: 1, minWidth: 130 },
        { field: 'nickName', headerName: 'Nick Name', flex: 1, minWidth: 130 },
        { field: 'loginCount', headerName: 'Login Count', flex: 1, minWidth: 140 },
        { field: 'totalBalance', headerName: 'Balance', flex: 1, minWidth: 120 },
        { field: 'userStatus', headerName: 'Status', flex: 1, minWidth: 100 },
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 100 },
        { field: 'creatorId', headerName: 'Creator ID', flex: 1, minWidth: 120 },
        { field: 'createdDate', headerName: 'Created Date', flex: 1, minWidth: 120 },
        { field: 'lastLogin', headerName: 'Last Login', flex: 1, minWidth: 180 },
    ];

    const [searchError, setSearchError] = useState(false);


    const getAuthParams = () => {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
            console.error("API key is missing");
            return null;
        }
        return {
            "api-key": apiKey,
        };
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return null;
        const date = new Date(timestamp);
        return date.toISOString().split('.')[0];
    };

    const fetchData = useCallback(async (pageNum, perPage) => {
        try {
            const params = getAuthParams();

            if (!params) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            // Passing page and perPage as query parameters
            const response = await api.get(`/v1/admin/user/stores`, {
                params: {
                    ...params,
                    page: pageNum,
                    perPage: perPage,
                },
            });

            const { data, code, success } = response.data;

            if (code !== 200 || !success) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            const rowsWithId = data.map((row, index) => ({
                ...row,
                id: row.id || index,
                lastLogin: formatTimestamp(row.lastLogin),
            }));

            setRows((prevRows) => [...prevRows, ...rowsWithId]);
            setIsEmpty(data.length === 0);
            setHasMore(data.length === perPage);
        } catch (error) {
            handle403Error(error, navigate);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    const fetchSearchData = useCallback(async (pageNum) => {
        try {
            const params = getAuthParams();

            if (!params) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            const payloadSearchParams = [
                { key: 'role', value: 'STORE' }, // Fixed role for stores
                ...Object.keys(searchFields).filter(key => searchFields[key] !== '').map(key => ({
                    key: key,
                    value: searchFields[key],
                })),
            ];

            const payload = {
                page: pageNum,
                size: perPage,
                searchParams: payloadSearchParams,
            };

            const response = await api.post(`/v1/admin/user/searchUser`, payload, { params });

            const { data, code, success } = response.data;

            if (code !== 200 || !success) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            const rowsWithId = data.map((row, index) => ({
                ...row,
                id: row.id || index,
                lastLogin: formatTimestamp(row.lastLogin),
            }));

            setRows((prevRows) => [...prevRows, ...rowsWithId]);
            setIsEmpty(data.length === 0);
            setHasMore(data.length === perPage);
        } catch (error) {
            handle403Error(error, navigate);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, searchFields]);

    const handleSearchChange = (e) => {
        const { name, value } = e.target;

        // Check if the input field is 'totalBalance' and ensure that the value is a number or empty string
        if (name === 'totalBalance' && (isNaN(value) || value.includes(' '))) {
            return; // Prevent non-numeric input
        }

        setSearchFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));

        setSearchError(false); // Clear the error when the user types
    };


    const handleSearch = () => {
        const areFieldsEmpty = Object.values(searchFields).every(value => value === '');
        if (areFieldsEmpty) {
            setSearchError(true); // Set the error state to true
            return;
        }

        setSearchError(false); // Clear the error state
        setRows([]);
        setPage(0);
        setIsSearchActive(true);
        fetchSearchData(0);
    };


    const handleReset = () => {
        setSearchFields({
            userName: '',
            totalBalance: '',
            userStatus: '',
            createdDate: '',
        });
        setRows([]);
        setPage(0);
        setIsSearchActive(false);
        setSearchError(false);
        fetchData(0, perPage);
    };

    const fetchMoreData = () => {
        setPage((prevPage) => {
            const nextPage = prevPage + 1;
            if (hasMore) {
                if (isSearchActive) {
                    fetchSearchData(nextPage);
                } else {
                    fetchData(nextPage, perPage);
                }
            }
            return nextPage;
        });
    };

    const toggleSearchForm = () => {
        setIsSearchFormVisible(!isSearchFormVisible);
    };

    // Track the scroll position to determine when to show "No more data"
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight / 2) { // 50vh condition
                setShowNoMoreData(true);
            } else {
                setShowNoMoreData(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        fetchData(0, perPage); // Initial fetch with page 0 and perPage 20
    }, [fetchData]);

    const endMessage = (showNoMoreData && !hasMore) ? <p>No more data</p> : null;

    return (
        <div>
            <ToastContainer />
            <h1 className="h3 mb-3" style={{ marginBottom: "20px", cursor: "pointer" }} onClick={toggleSearchForm}>
                <strong>Search</strong>
                <IconButton style={{ marginLeft: '5px', paddingTop: '2px' }}>
                    {isSearchFormVisible ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
            </h1>
            {isSearchFormVisible && (
                <div className="downdiv" style={{ marginBottom: "35px" }}>
                    <form
                        className="searchForm"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                            gap: "10px",
                        }}
                    >
                        <div>
                            <label htmlFor="userName">Store Name</label>
                            <input
                                type="text"
                                id="userName"
                                name="userName"
                                value={searchFields.userName}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="totalBalance">Total Balance</label>
                            <input
                                type="text"
                                id="totalBalance"
                                name="totalBalance"
                                value={searchFields.totalBalance}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="userStatus">Store Status</label>
                            <input
                                type="text"
                                id="userStatus"
                                name="userStatus"
                                value={searchFields.userStatus}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="createdDate">Created Date</label>
                            <input
                                type="date"
                                id="createdDate"
                                name="createdDate"
                                value={searchFields.createdDate}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                    </form>
                    {searchError && (
                        <p style={{ color: 'red', marginBottom: '10px', fontSize: '13px' }}>
                            Please fill in at least one search field before searching.
                        </p>
                    )}
                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        <button
                            type="button"
                            onClick={handleSearch}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="resetButton"
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#6c757d",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="h3"><strong>Stores</strong></h1>
                </div>
                <RefreshButton style={{ marginRight: 0, paddingRight: '10px' }} onClick={() => { setPage(0); setRows([]); fetchData(0, perPage); }}>Refresh</RefreshButton>
            </div>
            <InfiniteScroll
                dataLength={rows.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={isLoading && hasMore ? <h4>Loading...</h4> : null}
                endMessage={endMessage} // Shows "No more data" text based on scroll
            >
                <DataTable
                    columns={columns}
                    rows={rows}
                    isEmpty={isEmpty}
                    isLoading={isLoading}
                    getRowId={(row) => row.id}
                />
            </InfiniteScroll>
            <ScrollToTopButton />
        </div>
    );
};

export default Store;
