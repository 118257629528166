// AdminDashboard.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handle403Error } from '../utils/handle403Error';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    timeout: 5000,
    withCredentials: true,
});

function AdminDashboard() {
    const [activeSlotCount, setActiveSlotCount] = useState(0);
    const [activeUploadedGames, setActiveUploadedGames] = useState(0);
    const navigate = useNavigate();

    const getAuthParams = () => {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
            throw new Error("API key is missing");
        }
        return {
            "api-key": apiKey,
        };
    };

    useEffect(() => {
        const fetchSlotData = async () => {
            try {
                const params = getAuthParams();
                const response = await api.get("v2/slot/admin/get-all-slots-info", { params });
                const activeSlots = response.data.data.filter(item => item.slotStatus === "ACTIVE");
                setActiveSlotCount(activeSlots.length);
            } catch (error) {
                handle403Error(error, navigate);
            }
        };

        const fetchUploadedGames = async () => {
            try {
                const params = getAuthParams();
                const response = await api.get("v2/admin/game-info/all-games", { params });
                const activeGames = response.data.data.filter(item => item.status === "ACTIVE");
                setActiveUploadedGames(activeGames.length);
            } catch (error) {
                handle403Error(error, navigate);
            }
        };

        fetchSlotData();
        fetchUploadedGames();
    }, [navigate]);

    return (
        <div style={styles.adminDashboard}>
            <ToastContainer />
            <h1 className="h3 mb-3"><strong>Analytics Dashboard</strong></h1>
            <div className="row">
                <div className="col-xl-6 col-xxl-5 d-flex">
                    <div className="w-100">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Total Active Slots</h5>
                                            </div>
                                        </div>
                                        <h1 className="mt-1 mb-3">{activeSlotCount}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Total Active Games</h5>
                                            </div>
                                        </div>
                                        <h1 className="mt-1 mb-3">{activeUploadedGames}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    adminDashboard: {},
    liveRecords: {
        marginBottom: "20px",
    },
    recordDiv: {
        display: "flex",
        justifyContent: "space-around",
    },
    outerDiv: {
        margin: "10px",
    },
    totalSlot: {
        border: "1px solid #000",
        padding: "10px",
    },
    numberSlot: {
        fontSize: "24px",
        fontWeight: "bold",
    },
    totalGame: {
        border: "1px solid #000",
        padding: "10px",
    },
    numberGame: {
        fontSize: "24px",
        fontWeight: "bold",
    },
};

export default AdminDashboard;
