import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { Button, styled, IconButton } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTopButton from '../components/ScrollToTopButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const Recharge = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [page, setPage] = useState(0); // Track the current page
    const [hasMore, setHasMore] = useState(true);
    const [, setShowNoMoreData] = useState(false); // Track if we should show "No more data"
    const [searchFields, setSearchFields] = useState({
        cashValue: '',
        receiverUsername: '',
        balanceBefore: '',
        balanceAfter: '',
        transactionTimeStamp: '',
    });
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // For displaying error
    const navigate = useNavigate();

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 70 },
        { field: 'statementType', headerName: 'Method', flex: 1, minWidth: 100 },
        { field: 'cashTransactionType', headerName: 'Transaction Type', flex: 1, minWidth: 150 },
        { field: 'cashValue', headerName: 'Amount', flex: 1, minWidth: 90 },
        { field: 'senderUsername', headerName: 'Sender', flex: 1, minWidth: 140 },
        { field: 'receiverUsername', headerName: 'Receiver', flex: 1, minWidth: 140 },
        { field: 'balanceBefore', headerName: 'Balance Before', flex: 1, minWidth: 130 },
        { field: 'balanceAfter', headerName: 'Balance After', flex: 1, minWidth: 130 },
        { field: 'transactionTimeStamp', headerName: 'Recharge On', flex: 1, minWidth: 200 },
    ];

    const getAuthParams = () => {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
            console.error("API key is missing");
            return null;
        }
        return {
            "api-key": apiKey,
        };
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toISOString().split('.')[0];
    };

    const fetchData = useCallback(async (pageNum, searchParams = {}) => {
        try {
            const params = getAuthParams();

            if (!params) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            const payloadSearchParams = [
                { key: 'cashTransactionType', value: 'RECHARGE' },
                ...Object.keys(searchParams).filter(key => searchParams[key] !== '').map(key => {
                    let backendKey = key;

                    // Map frontend keys to backend keys
                    switch (key) {
                        case 'transactionTimeStamp':
                            backendKey = 'transactOn';
                            break;
                        // Add more mappings as needed
                        default:
                            backendKey = key;
                            break;
                    }

                    return {
                        key: backendKey,
                        value: searchParams[key]
                    };
                })
            ];

            const payload = {
                page: pageNum,
                size: 20,
                searchParams: payloadSearchParams
            };

            const response = await api.post(`/v1/admin/request-data/searchTransaction`, payload, { params });

            const { data, code, success } = response.data;

            if (code !== 200 || !success) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            const rowsWithId = data.map((row, index) => ({
                ...row,
                id: row.id || index,
                transactionTimeStamp: formatTimestamp(row.transactionTimeStamp)
            }));

            setRows((prevRows) => [...prevRows, ...rowsWithId]);
            setIsEmpty(data.length === 0);
            setHasMore(data.length === 20); // Set `hasMore` to false if less than 20 items are fetched
        } catch (error) {
            handle403Error(error, navigate);
            setHasMore(false); // In case of error, stop further fetching
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    const fetchAllDataWithPagination = useCallback(async (pageNum) => {
        try {
            const params = getAuthParams();

            if (!params) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false); // No more data to load
                return;
            }

            const response = await api.get(`/v1/admin/request-data/recharge`, {
                params: {
                    ...params,
                    page: pageNum, // Page number for pagination
                    perPage: 20,   // Number of records per page
                }
            });

            const { data, code, success } = response.data;

            if (code !== 200 || !success) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false); // No more data to load
                return;
            }

            const rowsWithId = data.map((row, index) => ({
                ...row,
                id: row.id || index,
                transactionTimeStamp: formatTimestamp(row.transactionTimeStamp)
            }));

            setRows((prevRows) => [...prevRows, ...rowsWithId]);
            setIsEmpty(data.length === 0);
            setHasMore(data.length === 20); // Set `hasMore` to false if less than 20 items are fetched
        } catch (error) {
            handle403Error(error, navigate);
            setHasMore(false); // In case of error, stop further fetching
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchAllDataWithPagination(0);
    }, [fetchAllDataWithPagination]);

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
    
        // Allow error message to disappear only when a valid value is typed
        if (['cashValue', 'balanceBefore', 'balanceAfter'].includes(name)) {
            // Only set value if it is a valid number or empty string
            if (/^\d*\.?\d*$/.test(value)) {
                setSearchFields((prevFields) => ({
                    ...prevFields,
                    [name]: value,
                }));
    
                // If a valid number is typed, remove the error message
                setErrorMessage('');
            }
        } else {
            // For other fields, simply update the value and remove the error message
            setSearchFields((prevFields) => ({
                ...prevFields,
                [name]: value,
            }));
            setErrorMessage('');
        }
    };
    

    const handleSearch = () => {
        const isAnyFieldFilled = Object.values(searchFields).some(value => value.trim() !== '');

        if (!isAnyFieldFilled) {
            setErrorMessage('Please fill in at least one search field before searching.');
            return;
        }

        setRows([]);
        setPage(0);
        setIsSearchActive(true); // Activate search mode
        fetchData(0, searchFields);
    };

    const handleReset = () => {
        setSearchFields({
            cashValue: '',
            receiverUsername: '',
            balanceBefore: '',
            balanceAfter: '',
            transactionTimeStamp: '',
        });
        setRows([]);
        setPage(0);
        setIsSearchActive(false); // Deactivate search mode
        setErrorMessage(''); // Remove the error message when reset is clicked
        fetchAllDataWithPagination(0);
    };

    const fetchMoreData = () => {
        setPage((prevPage) => {
            const nextPage = prevPage + 1;
            if (hasMore) {
                if (isSearchActive) {
                    fetchData(nextPage, searchFields); // Fetch search data with pagination
                } else {
                    fetchAllDataWithPagination(nextPage); // Fetch regular data with pagination
                }
            }
            return nextPage;
        });
    };

    const toggleSearchForm = () => {
        setIsSearchFormVisible(!isSearchFormVisible);
    };

    // Track the scroll position to determine when to show "No more data"
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight / 2) { // 50vh condition
                setShowNoMoreData(true);
            } else {
                setShowNoMoreData(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Updated logic for endMessage
    const endMessage = (page > 0 && rows.length > 0 && !hasMore) ? <p>No more data</p> : null;

    return (
        <div>
            <ToastContainer />
            <h1 className="h3 mb-3" style={{ marginBottom: "20px", cursor: "pointer" }} onClick={toggleSearchForm} >
                <strong>Search</strong>
                <IconButton style={{ marginLeft: '5px', paddingTop: '2px' }}>
                    {isSearchFormVisible ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
            </h1>
            {isSearchFormVisible && (
                <div className="downdiv" style={{ marginBottom: "35px" }}>
                    <form
                        className="searchForm"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                            gap: "10px",
                        }}
                    >
                        <div>
                            <label htmlFor="cashValue">Amount</label>
                            <input
                                type="text"
                                id="cashValue"
                                name="cashValue"
                                value={searchFields.cashValue}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="receiverUsername">Receiver</label>
                            <input
                                type="text"
                                id="receiverUsername"
                                name="receiverUsername"
                                value={searchFields.receiverUsername}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="balanceBefore">Balance Before</label>
                            <input
                                type="text"
                                id="balanceBefore"
                                name="balanceBefore"
                                value={searchFields.balanceBefore}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="balanceAfter">Balance After</label>
                            <input
                                type="text"
                                id="balanceAfter"
                                name="balanceAfter"
                                value={searchFields.balanceAfter}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="transactionTimeStamp">Recharge On</label>
                            <input
                                type="date"
                                id="transactionTimeStamp"
                                name="transactionTimeStamp"
                                value={searchFields.transactionTimeStamp}
                                onChange={handleSearchChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                    </form>
                    {errorMessage && <p style={{ color: 'red', marginTop: '10px',  fontSize: '13px' }}>{errorMessage}</p>}
                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        <button
                            type="button"
                            onClick={handleSearch}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="resetButton"
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#6c757d",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="h3"><strong>Recharge Record</strong></h1>
                </div>
                <RefreshButton style={{ marginRight: 0, paddingRight: '10px' }} onClick={() => { setPage(0); setRows([]); fetchAllDataWithPagination(0); }}>Refresh</RefreshButton>
            </div>
            <InfiniteScroll
                dataLength={rows.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={endMessage} // Updated to only show from page 1 onward and when there's no more data
            >
                <DataTable
                    columns={columns}
                    rows={rows}
                    isEmpty={isEmpty}
                    isLoading={isLoading}
                    getRowId={(row) => row.id}
                />
            </InfiniteScroll>
            <ScrollToTopButton />
        </div>
    );
};

export default Recharge;
