import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { handle403Error } from '../utils/handle403Error'; // Import the shared function

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const fetchActiveGames = async () => {
    try {
        const params = {
            "api-key": localStorage.getItem("apiKey"),
        };
        const response = await api.get("/v2/slot/admin/get-all-slots-info", {
            params,
        });
        return response.data.data
            .filter((item) => item.slotStatus === "ACTIVE")
            .map((item) => ({
                ...item,
                id: item.gameId,
            }));
    } catch (error) {
        console.error("Error fetching active games:", error);
        toast.error("Error fetching active games.");
        return [];
    }
};

const OnboardGame = ({ setOpen, onAddGame }) => {
    const [gameName, setGameName] = useState("");
    const [gameCode, setGameCode] = useState("");
    const [status, setStatus] = useState("ACTIVE");
    const [parentId, setParentId] = useState("");
    const [gameType, setGameType] = useState("");
    const [gameSubType, setGameSubType] = useState("");
    const [activeGames, setActiveGames] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const loadActiveGames = async () => {
            const games = await fetchActiveGames();
            setActiveGames(games);
        };

        loadActiveGames();
    }, []);

    const validateForm = () => {
        let newErrors = {};
        if (!gameName) newErrors.gameName = "Game Name is required";
        if (!gameCode) newErrors.gameCode = "Game Code is required";
        if (!status) newErrors.status = "Status is required";
        if (!parentId) newErrors.parentId = "Slot Name is required";
        if (!gameType) newErrors.gameType = "Game Type is required";
        if (gameType === "SLOT_MACHINE" && !gameSubType) newErrors.gameSubType = "Game Sub-Type is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const apiKey = localStorage.getItem("apiKey");

        if (!apiKey) {
            toast.error("Authentication details not found.");
            return;
        }

        try {
            const selectedSlot = activeGames.find((game) => game.slotName === parentId);
            const newParentId = selectedSlot ? selectedSlot.slotId : parentId;

            const response = await api.post(
                `/v2/admin/game-info/onboard-game`,
                {
                    gameName,
                    gameCode,
                    status,
                    parentId: newParentId,
                    gameType,
                    gameSubType,
                },
                {
                    params: {
                        "api-key": apiKey,
                    },
                }
            );

            const newGame = { ...response.data.data, id: response.data.data.gameId };
            onAddGame(newGame);
            toast.success(response.data.message);
            setOpen(false);
        } catch (error) {
            if (error.response && error.response.data) {
                const { message, error: errorMessage } = error.response.data;
                toast.error(message || errorMessage || "Error onboarding game.");
            } else {
                handle403Error(error, navigate);
            }
        }
    };

    const getSubTypeOptions = () => {
        if (gameType === "SLOT_MACHINE") {
            return (
                <>
                    <option value="THREE_BY_5">THREE_BY_5</option>
                    <option value="THREE_BY_3">THREE_BY_3</option>
                </>
            );
        }
        return <option value="">No Sub-Types Available</option>;
    };

    const handleChange = (field, value) => {
        switch (field) {
            case "gameName":
                setGameName(value);
                break;
            case "gameCode":
                setGameCode(value);
                break;
            case "status":
                setStatus(value);
                break;
            case "parentId":
                setParentId(value);
                break;
            case "gameType":
                setGameType(value);
                break;
            case "gameSubType":
                setGameSubType(value);
                break;
            default:
                break;
        }
        setErrors((prev) => ({ ...prev, [field]: "" }));
    };

    const styles = {
        form: {
            width: '100%',
            maxWidth: '600px',
            padding: '20px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 2,
            margin: 'auto',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            marginBottom: '15px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        label: {
            fontWeight: 'bold',
            fontSize: '14px',
            flex: '1',
        },
        inputErrorGroup: {
            display: 'flex',
            flexDirection: 'column',
            flex: '2',
        },
        input: {
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
        },
        error: {
            color: 'red',
            fontSize: '12px',
            marginTop: '5px',
            textAlign: 'left',
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '20px',
        },
        button: {
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
            marginRight: '10px',
            flex: '1',
        },
        submitButton: {
            backgroundColor: '#007bff',
            color: 'white',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
            border: '1px solid #ced4da',
        },
    };

    return (
        <div style={styles.form}>
            <h2 style={styles.header}>Onboard Game</h2>
            <form className="mainForm" onSubmit={handleSubmit}>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Game Name</label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            placeholder="Enter a Game Name"
                            value={gameName}
                            onChange={(e) => handleChange("gameName", e.target.value)}
                            style={styles.input}
                        />
                        {errors.gameName && <div style={styles.error}>{errors.gameName}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Game Code</label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            placeholder="Enter a Game Code"
                            value={gameCode}
                            onChange={(e) => handleChange("gameCode", e.target.value)}
                            style={styles.input}
                        />
                        {errors.gameCode && <div style={styles.error}>{errors.gameCode}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Status</label>
                    <div style={styles.inputErrorGroup}>
                        <select
                            value={status}
                            onChange={(e) => handleChange("status", e.target.value)}
                            style={styles.input}
                        >
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                        </select>
                        {errors.status && <div style={styles.error}>{errors.status}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Slot Name</label>
                    <div style={styles.inputErrorGroup}>
                        <select
                            value={parentId}
                            onChange={(e) => handleChange("parentId", e.target.value)}
                            style={styles.input}
                        >
                            <option value="">Select Slot Name</option>
                            {activeGames.map((game) => (
                                <option key={game.slotId} value={game.slotName}>
                                    {game.slotName}
                                </option>
                            ))}
                        </select>
                        {errors.parentId && <div style={styles.error}>{errors.parentId}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Game Type</label>
                    <div style={styles.inputErrorGroup}>
                        <select
                            value={gameType}
                            onChange={(e) => handleChange("gameType", e.target.value)}
                            style={styles.input}
                        >
                            <option value="">Select Game Type</option>
                            <option value="LANGUR_BURJA">LANGUR_BURJA</option>
                            <option value="KENO">KENO</option>
                            <option value="ROULETTE">ROULETTE</option>
                            <option value="SLOT_MACHINE">SLOT_MACHINE</option>
                        </select>
                        {errors.gameType && <div style={styles.error}>{errors.gameType}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Sub-Type</label>
                    <div style={styles.inputErrorGroup}>
                        <select
                            value={gameSubType}
                            onChange={(e) => handleChange("gameSubType", e.target.value)}
                            style={styles.input}
                        >
                            <option value="">Select Game Sub-Type</option>
                            {getSubTypeOptions()}
                        </select>
                        {errors.gameSubType && <div style={styles.error}>{errors.gameSubType}</div>}
                    </div>
                </div>
                <div style={styles.buttonGroup}>
                    <button type="submit" style={{ ...styles.button, ...styles.submitButton }}>
                        Onboard
                    </button>
                    <button type="button" style={{ ...styles.button, ...styles.cancelButton }} onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OnboardGame;
